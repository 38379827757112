import(/* webpackMode: "eager", webpackExports: ["RootLayoutProvider"] */ "/web/apps/cbv-learn/src/components/RootLayoutProvider.tsx");
;
import(/* webpackMode: "eager" */ "/web/apps/cbv-learn/src/utils/utilities.css");
;
import(/* webpackMode: "eager" */ "/web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["A11yScannerClient"] */ "/web/shared/a11y-scanner/src/A11yScannerClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteFooterEmbedPersistor"] */ "/web/shared/embed-persistor/src/SiteFooterEmbedPersistor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteNavigationEmbedPersistor"] */ "/web/shared/embed-persistor/src/SiteNavigationEmbedPersistor.tsx");
