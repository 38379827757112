'use client';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';

const Component = dynamic(() => import('./A11yScannerBase'), { ssr: false });

export const A11yScannerClient: React.FC<{ enable: boolean }> = ({
  enable,
}) => {
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    if (!enable) return;
    setMounted(true);
  }, [enable]);

  if (!mounted || !Component) return null;

  return (
    <React.Suspense fallback={null}>
      <Component />
    </React.Suspense>
  );
};
